import { Box, Flex, Heading, IconButton, Image, Text, VStack } from "@chakra-ui/react";
import { Container } from "@components/Container";
import { useCategories } from "@src/hooks/api";
import { ArrowLeftIcon, ArrowLeftSimpleIcon, ArrowRightIcon, ArrowRightSimpleIcon } from "@src/icons";
import { StrapiCategoriesCarousel } from "@src/types/strapi";
import { Category } from "@src/types/strapi/collections";
import { carouselDelayInMs } from "@src/utils/delay";
import Link from "next/link";
import { useRef } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import styles from "./Categories.module.scss";

const swiperCommonProps: SwiperProps = {
  slidesPerGroup: 1,
  modules: [Navigation, Autoplay],
};

interface RenderSwiperProps {
  data?: Category[];
  timeout: number;
}

const RenderSwiperByVariantDefault = (props: RenderSwiperProps) => {
  const SLIDES_PER_VIEW_DEFAULT = 4;
  const swiperRef = useRef<any>(null);
  const hasMinumumItemsToShowNavigation = props.data && props.data.length > SLIDES_PER_VIEW_DEFAULT;

  const swiperProps: SwiperProps = {
    ...swiperCommonProps,
    autoplay: {
      delay: props.timeout,
    },
    slidesPerView: SLIDES_PER_VIEW_DEFAULT,
    spaceBetween: 24,
    onBeforeInit: (swiper) => {
      swiperRef.current = swiper;
    },
    className: styles.categoriesSwiper,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 16,
      },
      425: {
        slidesPerView: 2,
        spaceBetween: 16,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
      1440: {
        slidesPerView: 4,
        spaceBetween: 24,
      },
    },
  };

  return (
    <Flex direction="row" width="100%" gridGap="45px" alignItems="center">
      {hasMinumumItemsToShowNavigation && (
        <IconButton
          icon={<ArrowLeftIcon color="primary.600" />}
          display={{ base: "none", md: "flex" }}
          variant="outline"
          colorScheme="primary"
          aria-label="next-button"
          backgroundColor="white"
          zIndex={2}
          borderRadius="100%"
          onClick={() => swiperRef.current?.slidePrev()}
        />
      )}

      <Swiper {...swiperProps}>
        {props.data?.map((item) => {
          return (
            <SwiperSlide key={item.id} className={styles.categoriesItemSwiper}>
              <Link href={`/produtos?category=${item.id}`}>
                <Flex>
                  <Image src={item.image.desktop_image.url} width="450px" height="451px" fit="cover" align="center" />
                  <Text
                    as="span"
                    color="white"
                    fontSize={{ base: "1.2rem", md: "1.5rem" }}
                    fontWeight="700"
                    zIndex={1}
                    position="absolute"
                    marginLeft={{ base: "0.5rem", md: "1rem" }}
                    marginBottom={{ base: "0.5rem", md: "1rem" }}
                    bottom={0}
                  >
                    {item.title}
                  </Text>
                </Flex>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>

      {hasMinumumItemsToShowNavigation && (
        <IconButton
          icon={<ArrowRightIcon color="primary.600" />}
          display={{ base: "none", md: "flex" }}
          variant="outline"
          colorScheme="primary"
          aria-label="next-button"
          backgroundColor="white"
          zIndex={2}
          borderRadius="100%"
          onClick={() => swiperRef.current?.slideNext()}
        />
      )}
    </Flex>
  );
};

const RenderSwiperByVariantBlackAndWhite = (props: RenderSwiperProps) => {
  const SLIDES_PER_VIEW_DEFAULT = 5;
  const swiperRef = useRef<any>(null);
  const hasMinimumItemsToShowNavigation = props.data && props.data.length > SLIDES_PER_VIEW_DEFAULT;

  const swiperProps: SwiperProps = {
    ...swiperCommonProps,
    autoplay: {
      delay: props.timeout,
    },
    slidesPerView: SLIDES_PER_VIEW_DEFAULT,
    spaceBetween: 32,
    onBeforeInit: (swiper) => {
      swiperRef.current = swiper;
    },
    className: styles.categoriasSwiperBlackAndWhite,
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 8,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
      1024: {
        slidesPerView: 4,
      },
      1440: {
        slidesPerView: 5,
      },
    },
  };

  return (
    <Flex direction="row" width="100%" gap="34px" alignItems={"center"}>
      {hasMinimumItemsToShowNavigation && (
        <IconButton
          icon={<ArrowLeftSimpleIcon />}
          display={{ base: "none", md: "flex" }}
          variant="unstyled"
          colorScheme="primary"
          aria-label="prev-button"
          backgroundColor="white"
          zIndex={2}
          onClick={() => swiperRef.current?.slidePrev()}
        />
      )}
      <Flex width={"full"} alignContent={"center"}>
        <Box width={"full"}>
          <Swiper {...swiperProps} centerInsufficientSlides>
            {props.data?.map((item) => {
              return (
                <SwiperSlide key={item.id} className={styles.categoriesItemSwiperBlackAndWhite}>
                  <Link href={`/produtos?category=${item.id}`}>
                    <Box>
                      <Image
                        src={item.image.desktop_image.url}
                        layout="responsive"
                        width={400}
                        height={250}
                        align="center"
                        style={{
                          borderRadius: "8px",
                          objectFit: 'cover',
                          objectPosition: 'center',
                        }}
                      />
                      <Text
                        as="span"
                        color="white"
                        fontSize="24px"
                        fontWeight="700"
                        zIndex={1}
                        position="absolute"
                        marginLeft={{ base: "0.5rem", md: "1rem" }}
                        marginBottom={{ base: "0.5rem", md: "1rem" }}
                        bottom={0}
                      >
                        {item.title}
                      </Text>
                    </Box>
                  </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
      </Flex>
      {hasMinimumItemsToShowNavigation && (
        <IconButton
          icon={<ArrowRightSimpleIcon />}
          display={{ base: "none", md: "flex" }}
          variant="unstyled"
          colorScheme="primary"
          aria-label="next-button"
          backgroundColor="white"
          zIndex={2}
          onClick={() => swiperRef.current?.slideNext()}
        />
      )}
    </Flex>
  );
};

export const CategoriesCarousel = (props: StrapiCategoriesCarousel) => {
  const { data } = useCategories();
  const timeout = props.timeout ? props.timeout * 1000 : 3000;
  const Slider = props.variant === "default" ? RenderSwiperByVariantDefault : RenderSwiperByVariantBlackAndWhite;

  return (
    <Container>
      <VStack width="100%">
        <Heading fontSize={{ base: "1.8rem", md: "2rem" }} marginBottom={{ base: "0.5rem", md: "2rem" }}>
          {props.title}
        </Heading>
        <Slider data={data} timeout={carouselDelayInMs(timeout)} />
      </VStack>
    </Container>
  );
};
